<template>
    <VueCountdown v-if="duration" :time="duration">
        <template slot-scope="props">
          <span v-if="compact">
            {{ label }} {{ props.days }} {{$t('results.formatting.time-d-only') | lowercase }}, {{ props.hours }} {{$t('results.formatting.time-h-only') | lowercase }}, {{ props.minutes }}  {{$t('results.formatting.time-m-only') | lowercase }}.
          </span>
          <span v-else>
            {{ label }} {{ props.days }} {{$t('shared.days') | lowercase }}, {{ props.hours }} {{$t('shared.hours') | lowercase }}, {{ props.minutes }}  {{$t('shared.minutes') | lowercase }}, {{ props.seconds }}  {{$t('shared.seconds') | lowercase }}.
          </span>
        </template>
    </VueCountdown>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import { Interval, DateTime } from "luxon";

export default {
  name: "CountDownTimer",
  components: {
    VueCountdown,
  },
  props: {
    label: String,
    countDownDate: String,
    compact: Boolean,
  },
  computed: {
    duration() {
      var later = DateTime.fromISO(this.countDownDate);
      var now = DateTime.now();
      var interval = Interval.fromDateTimes(now, later);
      return interval.length();
    }
  }
};
</script>

<style lang="scss">
</style>